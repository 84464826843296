<template>
    <div>
        <alert-component v-if="object.editable && object.editable == 'false'" :alert="alerts.editblock"></alert-component>
        <v-container v-if="filterPropertyItems(filterProperty) && filterPropertyItems(filterProperty).length > 0">
            <v-row dense>
                <v-col class="pa-0 ma-0" v-for="item in filterPropertyItems(filterProperty)" :key="item.description" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-checkbox
                        v-model="select"
                        :value="item.description"
                        class="caption pa-0 ma-0"
                        :disabled="object.editable && object.editable == 'false'"
                    >
                        <template v-slot:label>
                            <span class="body-2 black-text font-weight-medium">{{ item.description }}</span>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col class="pa-0 ma-0 text-right" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn
                        dark
                        color="primary"
                        @click="store"
                        :class="$css_button"
                        :disabled="object.editable && object.editable == 'false'"
                    >
                    Salvar
                    </v-btn>
                </v-col>    
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AlertComponent from '../alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent
    },

    created(){
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object: {
            handler:function(newVal) {
                this.setObject()
            },
            deep:true
        },
    },  
    
    data () {
        return {
            alerts: {
                editblock: {
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['Esse imóvel pertence a um condomínio e portanto as informações de Infra e Área de lazer deverāo ser editados no condomínio.']
                },
            },

            select:[],

            filterProperty: {
                model: this.object.model_property,
                tipos: '',
                regioes: '',
                order: ''
            },

            item: {
                items: []
            },

            items: {
                property_items: []
            },

            lixo: []
        }
    },

    methods: {
        setObject() {
            this.item.model = this.object.model
            this.item.item_id = this.object.item_id
            if(this.object.tipos && this.object.tipos != ''){
                this.filterProperty.tipos = this.object.tipos
            }
            if(this.object.regioes && this.object.regioes != ''){
                this.filterProperty.regioes = this.object.regioes
            }
            if(this.object.order && this.object.order != ''){
                this.filterProperty.order = this.object.order
            }

            this.fillSelect()  
            if(this.item.model == 'users' && this.object.items.length == 0){
                const items = this.filterPropertyItems(this.filterProperty).filter(model => model.default == 1)
                items.forEach(i => {
                        this.select.push(i.description)
                })
                this.store()
            }
        },

        fillSelect(){
            this.select = []
            if(this.object.items && this.object.items.length > 0){
                this.object.items.forEach(i => {
                    this.select.push(i.description)
                })
            }
        },

        store(){
            this.item.items = this.select
            this.$store.dispatch(this.object.method, this.item) 
                            .then(response => {
                                this.$snotify.success('Cadastro com sucesso!')
                                //this.$emit('callLoadItem', this.item.item_id)
                            })   
        }
    }
}
</script>