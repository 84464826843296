<template>
    <div class="text-center">
        <v-container fluid>
            <v-row> 
                <v-col class="text-h4">
                    O que deseja fazer?
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn large block dark color="pink">Quero cadastrar um novo imóvel</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn large dark block color="purple">Quero pesquisar um imóvel</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn large dark block color="primary">Quero uma lista de imóveis disponíveis</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'PropertyMenuComponent'
}
</script>