<template>
    <div>
        <v-card
            class="scroll pa-0"
            max-height="250"
            min-height="250"
            outlined
            id="table_border"
        >
            <v-simple-table dense>
                <thead fixed-header>
                    <tr>
                        <th :class="$css_title_module">
                            Plantas
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <v-card
                                class="mx-auto mt-4"
                                outlined
                            >
                                <v-data-table
                                    hide-default-footer
                                    :headers="headers"
                                    :items="object.plans"
                                    :items-per-page="10"
                                    sort-by="id"
                                    class="elevation-1 table-striped"
                                    :footer-props="{
                                    'items-per-page-text':'Itens por página',
                                    'items-per-page-options': [5, 10],
                                    'items-per-page-all-text': '',
                                    'page-text': '{0}-{1} de {2}'
                                }"
                                >
                                    <template v-slot:[`item.nome`]="{ item }">
                                        <span :class="$css_report_list">{{ item.nome }}</span>
                                    </template>

                                    <template v-slot:[`item.dormitorios`]="{ item }">
                                        <span :class="$css_report_list">{{ item.dormitorios }}</span>
                                    </template>

                                    <template v-slot:[`item.suites`]="{ item }">
                                        <span :class="$css_report_list">{{ item.suites }}</span>
                                    </template>

                                    <template v-slot:[`item.banheiros`]="{ item }">
                                        <span :class="$css_report_list">{{ item.banheiros }}</span>
                                    </template>

                                    <template v-slot:[`item.vagas`]="{ item }">
                                        <span :class="$css_report_list">{{ item.vagas }}</span>
                                    </template>

                                    <template v-slot:[`item.area`]="{ item }">
                                        <span :class="$css_report_list">{{ item.area }}</span>
                                    </template>

                                    <template v-slot:[`item.valor_venda`]="{ item }">
                                        <span :class="$css_report_list">{{ item.valor_venda | toCurrency }}</span>
                                    </template>

                                    <template v-slot:[`item.valor_locacao`]="{ item }">
                                        <span :class="$css_report_list">{{ item.valor_locacao | toCurrency }}</span>
                                    </template>
                                    
                                    <template v-slot:[`item.valor_condominio`]="{ item }">
                                        <span :class="$css_report_list">{{ item.valor_condominio | toCurrency }}</span>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        object: {
            require: true
        }
    },

    data () {
        return {
            headers: [
                { text: 'NOME', align: 'start', value: 'nome', },
                { text: 'DORMITÓRIO', value: 'dormitorios' },
                { text: 'SUÍTES', value: 'suites' },
                { text: 'BANHEIROS', value: 'banheiros' },
                { text: 'VAGAS', value: 'banheiros' },
                { text: 'ÁREA', value: 'area' },
                { text: 'VL VENDA', value: 'valor_venda' },
                { text: 'VL LOCAÇÃO', value: 'valor_locacao' },
                { text: 'VL COND.', value: 'valor_condominio' }
            ],
        }
    }    
}
</script>