import { render, staticRenderFns } from "./StatusComponent.vue?vue&type=template&id=55936b28&scoped=true&"
import script from "./StatusComponent.vue?vue&type=script&lang=js&"
export * from "./StatusComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55936b28",
  null
  
)

export default component.exports