<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row dense> 
                <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
                    <v-tooltip top max-width="650px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="params.multiple_codigo"
                                outlined
                                dense
                                label="Codigos"
                                append-icon="mdi-help-circle-outline"
                                v-on="on"
                                :class="$css_field"
                                clearable
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Código</span>
                                </template>
                            </v-text-field>
                        </template>
                        <span>
                            Para pesquisar mais de um codigo basta separar por virgula
                        </span>
                    </v-tooltip>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.multiple_status"
                        :items="items.status"
                        outlined
                        multiple
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_status.length - 1 }} opções)
                            </span>
                        </template>   

                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.multiple_setor"
                        :items="items.setores"
                        outlined
                        dense
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Setores</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_setor.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.multiple_tipo_imovel"
                        :items="items.tipos_imovel"
                        item-text="tipo"
                        item-value="tipo"
                        outlined
                        dense
                        multiple
                        clearable
                        :class="$css_field"
                        @change="searchSubTipoImovel"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo Imovel</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.tipo }}</span>
                            </div>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                                (+{{ params.multiple_tipo_imovel.length - 1 }} opções)
                            </span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.multiple_sub_tipo_imovel"
                        :items="items.sub_tipo_imovel"
                        outlined
                        dense
                        clearable
                        multiple
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Sub Tipo Imovel</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span>{{ item }}</span>
                            </div>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                                (+{{ params.multiple_sub_tipo_imovel.length - 1 }} opções)
                            </span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.multiple_finalidade"
                        :items="items.finalidade"
                        outlined
                        multiple
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Finalidade</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_finalidade.length - 1 }} opções)
                            </span>
                        </template>   

                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.dormitorios"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Dormitório(s)</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.banheiros"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Banheiro(s)</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.vagas"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vaga(s)</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.suites"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Suite(s)</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.salas"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Sala(s)</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_locacao_from"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl locação de</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_locacao_to"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl locação até</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_venda_from"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl venda de</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_venda_to"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl venda até</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_pacote_locacao_from"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl pacote locação de</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_pacote_locacao_to"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl pacote locação até</span>
                        </template>
                    </v-currency-field>
                </v-col>

                
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_from"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área mínima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_to"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área máxima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_total_from"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área total mínima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_total_to"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área total máxima</span>
                        </template>
                    </v-text-field>
                </v-col>



                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_util_from"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área útil minima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_util_to"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área útil máxima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_construida_from"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área construída minima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_construida_to"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área construída máxima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-tooltip top max-width="650px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="params.area_terreno_from"
                                outlined
                                dense
                                append-icon="mdi-help-circle-outline"
                                v-on="on"
                                :class="$css_field"
                                clearable
                                suffix="m²"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Área terreno minima</span>
                                </template>
                            </v-text-field>
                        </template>
                        <span>
                            Aplicável somente para terrenos e áreas
                        </span>
                    </v-tooltip>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-tooltip top max-width="650px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="params.area_terreno_to"
                                outlined
                                dense
                                append-icon="mdi-help-circle-outline"
                                v-on="on"
                                :class="$css_field"
                                clearable
                                suffix="m²"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Área terreno máxima</span>
                                </template>
                            </v-text-field>
                        </template>
                        <span>
                            Aplicável somente para terrenos e áreas
                        </span>
                    </v-tooltip>
                </v-col>



                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.em_condominio"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Em condominio?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.em_vila"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Em vila?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="params.filter_endereco"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.numero"
                        label="Número"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Número</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.apartamento"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Apto</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.complemento"
                        outlined
                        dense
                        required
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Complemento</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.zona"
                        :items="items.zona"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Zona</span>
                        </template>
                    </v-select>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.regiao"
                        :items="items.regiao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Região</span>
                        </template>
                    </v-select>
                </v-col>
                
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.places"
                        :items="items.places"
                        outlined
                        dense
                        item-text="distrito_cidade_estado"
                        item-value="all"
                        :class="$css_field"
                        multiple
                        clearable
                        :filter="filterData"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Locais</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.distrito_cidade_estado }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.places.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.cidade_estado"
                        :items="items.cities"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                        :filter="filterData"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cidade</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="params.bairro"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Bairro</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="params.condominio_avulso"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Condomínio avulso</span>
                        </template>
                    </v-text-field>
                </v-col>
                 <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.imovel_ocupado"
                        :items="items.imovel_ocupado"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Ocupação</span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <v-row dense v-if="more_filters">
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.private_areas"
                        :items="filterPropertyItems(private_areas)"
                        outlined
                        dense
                        item-text="description"
                        item-value="description"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área privativa</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.description }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.private_areas.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.recreations"
                        :items="filterPropertyItems(recreations)"
                        outlined
                        dense
                        item-text="description"
                        item-value="description"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área lazer</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.description }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.recreations.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.infrastructures"
                        :items="filterPropertyItems(infrastructures)"
                        outlined
                        dense
                        item-text="description"
                        item-value="description"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Infra</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.description }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.infrastructures.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>    
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.multiple_colaborator"
                        :items="items.users"
                        outlined
                        dense
                        item-text="name"
                        item-value="id"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Colaborador</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.name }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_colaborator.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.multiple_condominium"
                        :items="condominiums"
                        outlined
                        dense
                        item-text="nome"
                        item-value="id"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Condominio</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.nome }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_condominium.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col> 
                <v-col  cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="params.proprietario"
                        outlined
                        dense
                        required
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Proprietário</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="params.proprietario_telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Proprietário telefone</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12"  xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="params.proprietario_email"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Proprietário e-mail</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                   <v-select
                        v-model="params.em_condominio"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Em condominio?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                   <v-select
                        v-model="params.em_vila"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Em vila?</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.estado_imovel"
                        :items="items.estado_imovel"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado do imovel</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.multiple_tipo_permuta"
                        :items="items.tipos_permuta"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipos de Permuta</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="params.multiple_perto_de"
                        :items="items.perto_de"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Perto de:</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.perto_de.length - 1 }} opções)
                            </span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.multiple_garantia_locacao"
                        :items="items.garantia_locacao"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Garantias aceitas</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_garantia_locacao.length - 1 }} opções)
                            </span>
                        </template>

                    </v-select>
                </v-col>

                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                   <v-select
                        v-model="params.have_photos"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Com photo?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                   <v-select
                        v-model="params.have_videos"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Com video?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                   <v-select
                        v-model="params.have_boards"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Com placa?</span>
                        </template>
                    </v-select>
                </v-col> 
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.data_cadastro_from"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="params.data_cadastro_from"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Dt cadastro de</span>
                                </template>  
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="data_cadastro_from" @input="calendar.data_cadastro_from = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.data_cadastro_to"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="params.data_cadastro_to"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Dt cadastro até</span>
                                </template>    
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="data_cadastro_to" @input="calendar.data_cadastro_to = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.data_atualizacao_from"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="params.data_atualizacao_from"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Dt atualização de</span>
                                </template>  
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="data_atualizacao_from" @input="calendar.data_atualizacao_from = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.data_atualizacao_to"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="params.data_atualizacao_to"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Dt atualização até</span>
                                </template>    
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="data_atualizacao_to" @input="calendar.data_atualizacao_to = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col v-if="builders && builders.length > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.multiple_builder_id"
                        :items="builders"
                        outlined
                        dense
                        item-text="nome"
                        item-value="id"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Construtoras</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.nome }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_builder_id.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.publicar_imovel"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Publicar o imóvel</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.branch_id"
                        :items="branches"
                        outlined
                        dense
                        :class="$css_field"
                        item-value="id"
                        item-text="descricao"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Unidade</span>
                        </template>
                    </v-select>
                </v-col>   
                <v-col  cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.portal_id"
                        :items="portalitems"
                        outlined
                        dense
                        :class="$css_field"
                        item-text="nome"
                        item-value="id"
                        clearable
                        @change="setPortalModels"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Portal</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.modelo"
                        :items="items.portal_models"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                        @change="setPortalModels"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Modelo</span>
                        </template>
                    </v-select>
                </v-col> 
                <v-col v-if="params.portal_id" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                     <v-checkbox
                        class="ma-0 pa-1"
                        v-model="params.marcado"
                        label="Imóveis não marcados no portal"
                        value="Não"
                    ></v-checkbox>
                </v-col> 
            </v-row>

            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="blue darken-1"
                        text  
                        @click.prevent="changeFilters" 
                        :class="$css_button"
                    >
                        <span v-if="!more_filters">Exibir mais filtros</span> 
                        <span v-else>Exibir menos filtros</span>                        
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text  
                        @click.prevent="clear" 
                        :class="$css_button"
                    >
                        Limpar
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click.prevent="submit"
                        :class="$css_button"
                    >
                        Pesquisar
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form>    
    </div>
</template>

<script>
import { STATUS_PROPERTY_ITEMS, TIPO_IMOVEL_PROPERTY_ITEMS, FINALIDADES_IMOVEL, SIM_NAO_ITEMS, TIPO_PERMUTA_ITEMS, ESTADO_IMOVEL_ITEMS, PERTO_DE_ITEMS, GARANTIA_LOCACAO_ITEMS, REGIAO_IMOVEL_ITEMS, ZONA_IMOVEL_ITEMS, OCUPACAO_IMOVEL_ITEMS } from '../../properties/config_properties/config_properties.js' 

export default {
    created(){
        this.loadPlaces()
        this.loadUsers()
        this.loadCondominiums()
        this.loadPortalItems()
        this.loadBuilders()
        this.loadBranches()
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },

        condominiums () {
            return this.$store.state.condominiums.items.data
        },

        portalitems() {
            return this.$store.state.portal_items.items.data    
        },

        builders() {
            return this.$store.state.builders.items.data    
        },

        branches() {
            return this.$store.state.branches.items.data   
        },
    },

    watch: {
        data_cadastro_from () {
            this.params.data_cadastro_from = this.formatDate(this.data_cadastro_from)
        },

        data_cadastro_to () {
            this.params.data_cadastro_to = this.formatDate(this.data_cadastro_to)
        },

        data_atualizacao_from () {
            this.params.data_atualizacao_from = this.formatDate(this.data_atualizacao_from)
        },

        data_atualizacao_to () {
            this.params.data_atualizacao_to = this.formatDate(this.data_atualizacao_to)
        },
    }, 

    data() {
        return {
            data_cadastro_from: '',
            data_cadastro_to: '',
            data_atualizacao_from: '',
            data_atualizacao_to: '',

            calendar: {
                data_cadastro_from: false,
                data_cadastro_to: false,
                data_atualizacao_from: false,
                data_atualizacao_to: false
            },

            place_params: {
                distinct: 'true',
                paginate: 'false',
            },

            items_params: {
                paginate: 'false'
            },

            params: {
                id: '',
                multiple_status: ['Ativo','Desatualizado'],
                multiple_cidade: [],
                data_cadastro_from: '',
                data_cadastro_to: '',
                data_atualizacao_from: '',
                data_atualizacao_to: ''
            },
            
            items: {
                status: STATUS_PROPERTY_ITEMS,
                setores: ['Venda','Locação'],
                tipos_imovel: TIPO_IMOVEL_PROPERTY_ITEMS,
                sub_tipo_imovel: [],
                finalidade: FINALIDADES_IMOVEL,
                sim_nao: SIM_NAO_ITEMS,
                tipos_permuta: TIPO_PERMUTA_ITEMS,
                estado_imovel: ESTADO_IMOVEL_ITEMS,
                perto_de: PERTO_DE_ITEMS, 
                garantia_locacao: GARANTIA_LOCACAO_ITEMS,
                regiao: REGIAO_IMOVEL_ITEMS,
                portal_models: [],
                users: [],
                places: [],
                cities: [],
                zona: ZONA_IMOVEL_ITEMS,
                imovel_ocupado: OCUPACAO_IMOVEL_ITEMS,   
            },

            more_filters: false,

            private_areas: {
                model: "private_areas",
                order: "description"
            },

            infrastructures: {
                model: "infrastructures",
                order: "order"
            },

            recreations: {
                model: "recreations",
                order: "order"
            },

        }
    },

    methods: {
        loadUsers(){
            const user_params = {}
            user_params.paginate = 'false'
            user_params.all = 'true'
            this.$store.dispatch('loadPromisseUsers', user_params)
                        .then(response => {
                            this.items.users = response.data   
                        })
                        .catch(error => {
                            console.log(error)
                        })
        },

        loadCondominiums () {
            this.$store.dispatch('loadCondominiums', this.items_params) 
        },

        loadPlaces(){
            this.$store.dispatch('loadPromissePlaces', this.place_params) 
                        .then(response => {
                            this.items.places = response.data   
                            const cities = []
                            this.items.places.forEach(i => {
                                var item = ''
                                item = i.cidade + ', ' + i.estado_sigla
                                cities.push(item)
                            })

                            this.items.cities = [...new Set(cities)].sort()

                        })
                        .catch(error => {
                            console.log(error)
                        })
        },

        loadPortalItems(){
            this.$store.dispatch('loadPortalItems')
        },

        loadBuilders () {
            this.$store.dispatch('loadBuilders', this.items_params) 
        },

        loadBranches () {
            this.$store.dispatch('loadBranches') 
        },

        changeFilters(){
            this.more_filters = !this.more_filters
        },

        setPortalModels(){
            this.items.portal_models = []
            var modelos = this.portalitems.filter(model => model.id === this.params.portal_id)
            if(modelos[0].modelos.length > 0){
                this.items.portal_models = modelos[0].modelos 
            }
        },

        clear() {
            this.$refs.form.reset()
        },

        submit(){
            this.params.id = ''
            this.$emit('callLoadItems',this.params)
        },

        searchSubTipoImovel(){
            this.items.sub_tipo_imovel = []
            this.params.multiple_sub_tipo_imovel = []
            this.params.multiple_tipo_imovel.forEach(element => {
                var sub = this.items.tipos_imovel.filter(model => model.tipo === element)
                if(sub.length > 0 && sub[0].subtipos.length > 0){
                    sub[0].subtipos.forEach(element => {
                        this.items.sub_tipo_imovel.push(element)
                    });
                    
                }
                
            });
        },
        
    }
}
</script>