<template>
    <div>
        <v-card
            class="pa-0"
            outlined
            id="table_border"
        >
            <v-simple-table dense>
                <thead fixed-header>
                    <tr>
                        <th colspan="2" :class="$css_title_module">
                            Principal
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Status</td>
                        <td :class="$css_show_description" width="75%">{{ object.status }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Nome</td>
                        <td :class="$css_show_description">{{ object.nome }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Endereço</td>
                        <td :class="$css_show_description">{{ object.endereco_completo }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        object: {
            require: true
        }
    }
}
</script>