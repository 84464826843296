<template>
    <div v-if="object && object.endereco != ''">
        <v-card
            class="scroll pa-0 text-center"
            max-height="350"
            min-height="350"
            outlined
            id="table_border"
            color="#F0F0F0"
        >   
        <iframe :src="map" height="350" width="100%" frameBorder="0"></iframe>
            <!--iframe
                width="100%"
                height="350"
                style="border:0"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                :src="'https://www.google.com/maps/embed/v1/q=' + this.object.endereco + ',' + this.object.numero + '-' + this.object.bairro + ',' + this.object.cidade + '-' + this.object.estado + ',Brasil' "
                >      
            </iframe-->

        </v-card>
    </div>
</template>

<script>
export default {
    created(){
        this.setObject()
    },

     watch: {
        object () {   
            this.setObject()
        },
    },

    props: {
        object: {
            require: true
        }
    },

    data() {
        return {
            map: '',
        }
    },

    methods: {
        setObject(){
            this.map = 'https://maps.google.com/maps?q='+ this.object.latitude +',' + this.object.longitude +'&z=16&output=embed'  
        },
    }
}
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 130px;
  width: 50%;
}
</style>