<template>
    <div>
        <div v-if="alerts.finish.show">
            <alert-component class="mt-4" :alert="alerts.finish"></alert-component>
        </div>
        <div v-else>
            <v-form ref="form">
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-text-field
                            dense
                            outlined
                            v-model="item.subject"
                            label="Assunto"
                            readonly
                            filled
                            
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-text-field
                            dense
                            outlined
                            v-model="details_object"
                            :label="label_object"
                            readonly
                            filled
                            
                        ></v-text-field>
                    </v-col>
                </v-row> 
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-text-field
                            dense
                            outlined
                            v-model="solicitante"
                            label="Solicitante"
                            readonly
                            filled
                            
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-textarea
                            v-model="item.comment"
                            :rules="[rules.required]"
                            outlined
                            dense
                            :class="$css_field"
                            counter
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Descreva sua solicitação</span>
                            </template>
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row dense>  
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">    
                        <v-btn
                            dark
                            color="primary"
                            @click="validate()"
                            :class="$css_button"
                        >
                            Gravar
                        </v-btn>
                    </v-col>   
                </v-row> 
            </v-form>
        </div>
    </div>
</template>

<script>
import AlertComponent from '@/components/alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent
    },

    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        }
    },

    data () {
        return {
            alerts: {
                 finish: {
                    show: false,
                    type: 'success',
                    icon: 'mdi-check-circle',
                    messages: ['Solicitação cadastrada com sucesso. Aguarde o retorno do seu gerente.']
                },
            },
            solicitante: '',
            item: {
                model: '',
                item_id: '',
                subject: '',
                comment: ''
            },
            item_object: '',
            label_object: '',
            details_object: '',
            rules: {
                required: value => !!value || 'Obrigatório',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            }
        }
    },

    methods: {
        setObject(){
            this.item.item_id = this.object.object.id
            this.item.model = this.object.model
            this.item.subject = this.object.subject
            
            let details = ''
            if(this.item.model == 'properties'){
                this.label_object = 'Imóvel'
                if(this.object.object.codigo){
                    details += 'CODIGO ' + this.object.object.codigo + ' - '
                }
                if(this.object.object.endereco){
                    details += this.object.object.endereco
                }
                if(this.object.object.numero && this.object.object.numero > 0){
                    details += ', ' + this.object.object.numero
                }
                if(this.object.object.apartamento && this.object.object.apartamento > 0){
                    details += ' APTO ' + this.object.object.apartamento
                }
                if(this.object.object.complemento && this.object.object.complemento > 0){
                    details += ' COMPL ' + this.object.object.complemento
                }
            }

            this.details_object = details
            this.solicitante = this.me.name + ' ( ' + this.me.email + ' )'
        },
        
        validate () {
            if (this.$refs.form.validate()) {
                this.store(this.item)
            }
        },

        store(item){
            this.$store.dispatch('storeUserRequest', item)
                        .then(response => {
                            this.alerts.finish.show = true
                            //this.$snotify.success('Cadastro com sucesso!')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        }, 
    },
}
</script>

<style scoped>

</style>