<template>
    <div>
        <v-card
            class="pa-0"
            outlined
            id="table_border"
        >
            <v-simple-table dense>
                 <thead fixed-header>
                    <tr>
                        <th colspan="2" :class="$css_title_module">
                            Dados da Propriedade
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Setores</td>
                        <td :class="$css_show_description" width="75%">{{ item.setores | toCommaSpace }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Tipos de Imovel</td>
                        <td :class="$css_show_description">{{ item.tipos_imovel | toCommaSpace }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Metragem </td>
                        <td :class="$css_show_description">
                            <span class="mr-4" v-if="item.metragem_minima">
                                <span :class="$css_show_title">Min:</span> 
                                {{ item.metragem_minima }} m²
                            </span>
                            <span class="m-2" v-if="item.metragem_maxima">
                                <span :class="$css_show_title">Max:</span> 
                                {{ item.metragem_maxima }} m²
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Frente</td>
                        <td :class="$css_show_description">{{ item.metragem_frente }} m²</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Área Construída</td>
                        <td :class="$css_show_description">{{ item.area }} m²</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Vl Locaçāo R$</td>
                        <td :class="$css_show_description"> 
                            <span class="mr-4" v-if="item.valor_minimo_locacao">
                                <span :class="$css_show_title">Min:</span> 
                                {{ item.valor_minimo_locacao | toCurrency }}
                            </span>
                            
                            <span class="m-2" v-if="item.valor_maximo_locacao">
                                <span :class="$css_show_title">Max:</span> 
                                {{ item.valor_maximo_locacao | toCurrency }}
                            </span>
                        </td>
                    </tr> 
                    <tr>
                        <td :class="$css_show_title" id="td_border">Vl Venda R$</td>
                        <td :class="$css_show_description"> 
                            <span class="mr-4" v-if="item.valor_minimo_venda">
                                <span :class="$css_show_title">Min:</span> 
                                {{ item.valor_minimo_venda | toCurrency }}
                            </span>
                            <span class="m-2" v-if="item.valor_maximo_venda">
                                <span :class="$css_show_title">Max:</span> 
                                {{ item.valor_maximo_venda | toCurrency }}
                            </span>
                        </td>
                    </tr> 
                    <tr>
                        <td :class="$css_show_title" id="td_border">Vl Venda Max m²</td>
                        <td :class="$css_show_description">{{ item.valor_maximo_metro_quadrado_venda | toCurrency }}</td>
                    </tr>  
                    <tr>
                        <td :class="$css_show_title" id="td_border">VGV</td>
                        <td :class="$css_show_description">{{ item.vgv | toCurrency }}</td>
                    </tr>
                    <tr>
                        <td :class="$css_show_title" id="td_border">Zonas</td>
                        <td :class="$css_show_description">{{ item.zonas | toCommaSpace }}</td>
                    </tr>  
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>

<script>
export default {
    created(){
        this.setObject()
    },

    watch: {
        object () {
            this.setObject()
        }
    },

    props: {
        object: {
            require: true
        }
    },

    data() {
        return {
            item: {}
        }
    },

    methods: {
        setObject(){
            this.item = {}
            if(this.object && this.object.length > 0){
                this.item = this.object[0]
            }
        }
    }
}
</script>