<template>
    <div>
        <v-container>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        outlined
                        id="table_border"
                    >
                        <v-simple-table dense>
                            <thead fixed-header>
                                <tr>
                                    <th>
                                        <v-avatar
                                            class="ma-0"
                                            tile
                                            width="100px"
                                            height="100px"
                                        >
                                            <v-img 
                                                v-if="branch_logo && branch_logo != ''"
                                                :src="branch_logo"
                                                :lazy-src="branch_logo"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                    >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="lighten-5"
                                                    ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                            <v-icon v-else large dark>
                                                mdi-camera 
                                            </v-icon>
                                        </v-avatar>
                                    </th>
                                    <th class="text-h5 black--text">
                                     Código {{ object.codigo }} -  {{ object.titulo_publicitario }}
                                    </th>
                                </tr>
                            </thead>
                        </v-simple-table>
                   </v-card>                                    
                </v-col>    
            </v-row>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        v-if="object.photos_total"
                        outlined
                        id="table_border"
                    >
                        <v-carousel 
                            :height="img.height"
                            hide-delimiters 
                            :show-arrows="false"
                        >
                            <v-carousel-item
                                reverse-transition="fade-transition"
                                transition="fade-transition"
                                v-for="(item, i) in object.photos_total" :key="i"
                            >
                                <v-sheet
                                    height="100%"
                                >
                                <v-img
                                    contain
                                    position="center"
                                    :max-height="img.height"
                                    :min-height="img.height"
                                    :src="item.urlremoto"
                                    :lazy-src="item.urlremoto"
                                >
                                    <div>
                                    <v-chip
                                        v-if="item.description != null"
                                        class="ma-2"
                                        color="pink"
                                        label
                                        text-color="white"
                                        >
                                        <v-icon left>
                                            mdi-label
                                        </v-icon>
                                        {{ item.description }}
                                    </v-chip>  
                                    </div>
                                </v-img>
                                </v-sheet>
                            </v-carousel-item>
                        </v-carousel> 
                    </v-card> 
                </v-col>
            </v-row>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        class="pa-2"
                        outlined
                        id="table_border"
                    >
                    {{ object.texto_publicitario.substr(0,600) }}
                    </v-card>
                </v-col>
            </v-row>
            <v-row dense v-if="object.valor_venda > 0 || object.valor_locacao > 0">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        class="pa-2 text-center"
                        outlined
                        id="table_border"
                    >
                    <span class="text-h5" v-if="object.valor_venda && object.valor_venda > 0">Venda {{ object.valor_venda | toCurrency }}</span>      
                    <span  v-if="object.valor_venda && object.valor_venda > 0" class="mr-2"></span>

                    <span class="text-h5" v-if="object.valor_locacao && object.valor_locacao > 0">Locaçāo {{ object.valor_locacao | toCurrency }}</span> 
                    <span v-if="object.valor_locacao && object.valor_locacao > 0" class="mr-2"></span>

                    <span class="text-h5 ml-3" v-if="object.valor_condominio">Condomínio {{ object.valor_condominio | toCurrency }}</span> 
                    
                    </v-card>
                </v-col>
            </v-row>                             
        </v-container>
    </div>
</template>

<script>

export default {    
    props: {
        object: {
            require: true,
        },

        branch_logo: {
            require: false,
        }
    },

    data () {
        return {
            modelo: 1,
            img:{
                height: '600px'
            }
        }   
    },

}
</script>