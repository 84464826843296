import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginComponent from '../components/login/LoginComponent'
import CloseRequestPasswordComponent from '../components/closerequestpassword/CloseRequestPasswordComponent'
import AdminComponent from '../components/AdminComponent'
import DashboardComponent from '../components/dashboard/DashBoardComponent'
import UserComponent from '../components/users/UserComponent'
import BranchComponent from '../components/branches/BranchComponent'
import TenantComponent from '../components/tenants/TenantComponent'
import BuilderComponent from '../components/builders/BuilderComponent'
import CondominiumComponent from '../components/condominiums/CondominiumComponent'
import PropertyComponent from '../components/properties/PropertyComponent'
import PropertyMenuComponent from '@/components/properties/PropertyMenuComponent'
import FavoriteComponent from '../components/favorites/FavoriteComponent'
import FacComponent from '../components/facs/FacComponent.vue'
import ProposalComponent from '../components/proposals/ProposalComponent'
import UserRequestComponent from '../components/user_requests/UserRequestComponent'

import store from '../store/index.js'

import { NAME_TOKEN } from '../config/config'
import { generateCacheBuster } from '../utils/cacheBuster';

Vue.use(VueRouter)

const routes = [
    { path: '/login', component: LoginComponent, name: 'login' },
    { path: '/closerequestpassword', component: CloseRequestPasswordComponent, name: 'closerequestpassword' },
    
    {
        path: '/admin',
        component: AdminComponent,
        meta: {auth: true},
        children: [
            { path: '/dashboard', component: DashboardComponent, name: 'admin.dashboard' },
            { path: '/users', component: UserComponent, name: 'admin.users.index'},
            { path: '/branches', component: BranchComponent, name: 'admin.branches.index' },
            { path: '/tenants', component: TenantComponent, name: 'admin.tenants.index' },
            { path: '/builders', component: BuilderComponent, name:'admin.builders.index' },
            { path: '/condominiums', component: CondominiumComponent, name:'admin.condominiums.index' },
            { path: '/properties', component: PropertyComponent, name:'admin.properties.index' },
            { path: '/properties_new', component: PropertyMenuComponent, name:'admin.propertymenu' },
            { path: '/favorites', component: FavoriteComponent, name:'admin.favorites.index' },
            { path: '/proposals', component: ProposalComponent, name:'admin.proposals.index' },
            { path: '/facs', component: FacComponent, name:'admin.facs.index' },
            { path: '/userrequests', component: UserRequestComponent, name:'admin.userrequests.index'}
        ]
    },

    { path: '/', component: LoginComponent, name: 'root'}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.auth) && !store.state.auth.authenticated) {
        const token = localStorage.getItem(NAME_TOKEN)
        if(!token){
            return router.push({name: 'login'})  
        }else{
            store.dispatch('checkLogin')
        } 
        //store.commit('CHANGE_URL_BACK', to.name) // apos o refresh ele volta para a pagina atual
        //return router.push({name: 'login'})
    }

    if (!to.query._) {
        const cacheBuster = generateCacheBuster();
        next({
            ...to,
            query: { ...to.query, _: cacheBuster }
        });
    } else {
        next();
    }
})

export default router
