<template>
  <v-app id="inspire">
    <v-row dense>
        <v-col cols="8" class="hidden-sm-and-down">
            <v-card 
                height="100%" 
                :style="images.back"
            >
            </v-card>                
        </v-col>
        <v-col class="text-center white pa-6">
            <img src="img/logo.png" width="200" style="margin-top: 200px;">
            <div class="mt-2">
                <alert-component :alert="alerts.login"></alert-component>
            </div>
               
            <v-form ref="form">
                <v-text-field
                    outlined
                    append-icon="mdi-email"
                    v-model="formData.email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                    autofocus
                >
                </v-text-field>

                <v-text-field v-if="!forgetpassword"
                    v-model="formData.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Senha"
                    autocomplete="off"
                    @click:append="show1 = !show1"
                    outlined
                    @keydown.enter="validate"
                ></v-text-field>

                <div class="body-2 text-right mt-0 mb-6">
                    <a v-if="!forgetpassword" @click="ForgetPassword()">
                        Esqueceu a senha?
                    </a>
                    <a v-if="forgetpassword" @click="ForgetPassword()">
                        Voltar para o login
                    </a>
                </div>
                    
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    large
                    block
                    @click="validate"
                    v-if="!forgetpassword"   
                >
                    Entrar
                </v-btn>

                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    large
                    block
                    @click="validate2"
                    v-if="forgetpassword"
                    elevation="0"
                    
                >
                    Solicitar nova Senha
                </v-btn>
            </v-form>    

            <p>&nbsp;</p>
            <p class="body-2"> {{ new Date().getFullYear() }} — AYT Site e Sistemas </p> 
            <p class="body-2"> 
                Tel: (11) 3042-3243 (WhatsApp)  <br />
                E-mail: contato@ayt.com.br
            </p>        
        </v-col>
    </v-row>
    
    <vue-snotify></vue-snotify>
        
  </v-app>
</template>

<script>
import AlertComponent from '@/components/alert/AlertComponent.vue'

import axios  from 'axios'

export default {
    components: {
        AlertComponent
    },

    created(){
        this.closeRequestPassword()
    },

    data () {   
        return {
            loading: false,
            alerts: {
                login: {
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['Em função das recentes atualizações, recomendamos utilizar a janela anônima.']
                },
            },

            images: {
                logo: 'logo.png',
                back: `background: url('./img/welcome.jpg') no-repeat center center; background-size: cover;`
            },

            formData: {
                email: '',
                password: '',
            },
            
            show1: false,
            forgetpassword: false,
            sendemail: false,

            emailRules: [
                v => !!v || 'E-mail e obrigatório',
                v => /.+@.+/.test(v) || 'E-mail não é válido',
            ],

            passwordRules: [
                v => !!v || 'Senha obrigatória',
            ],   
        }
    },
    
    methods: {
        login () {
            this.loading = true

            this.formData.device_name = this.$browserDetect.meta.ua
            this.$store.dispatch('login', this.formData)    
                        .then(() => {
                            this.$router.push({name: 'admin.dashboard'})
                            this.$store.dispatch('loadPortalItems')
                        })
                        .catch(error => {
                            this.loading = false
                            this.$snotify.error('E-mail ou senha incorreto', error)
                        })
        },

        validate () {
            if (this.$refs.form.validate()) {
              this.snackbar = true
              this.login()
            }
        },

        validate2 () {
            if (this.$refs.form.validate()) {
              this.snackbar = true
              this.SendRequestPassword()
            }
        },

        ForgetPassword(){
            this.forgetpassword = !this.forgetpassword
        },

        SendRequestPassword(){
            this.$store.dispatch('sendRequestPassword', this.formData)
                        .then(() => {
                           this.$snotify.success('Verifique seu e-mail. Solicitaçāo enviada')
                           this.ForgetPassword()
                        })
                        .catch(error => {
                            this.loading = false
                            this.$snotify.error('erro', error)
                        })
        },

        closeRequestPassword(){
            if(this.$router.currentRoute.query.request && this.$router.currentRoute.query.request != ''){
                const params = {}
                params.item_id = this.$router.currentRoute.query.request 

                this.$store.dispatch('closeRequestPassword', params)
                        .then(() => {
                           this.$snotify.success('Verifique seu e-mail. Uma nova senha foi enviada')
                        })
                        .catch(error => {
                            this.loading = false
                            this.$snotify.error('erro', error)
                        })
            }
        }        

    }
}
</script>

<style scoped>
#inspire {
  background-color:#f1f4f6;
  font-family: 'Verdana';
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  width: 50%;
}

</style>
