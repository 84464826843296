<template>
    <div>
        <v-alert 
            v-if="requires && requires.length > 0"
            text
            dense
            color="error"          
            icon="mdi-alert-outline"    
        >
            <span class="black--text mr-2">O sistema detectou a ausência de informações nas abas: </span>
            <span v-for="alert in requires" :key="alert.description">
                <v-chip
                    label
                    small
                    class="ma-1"
                    dark
                    color="red"
                    @click="changeTab(alert.tab)"
                >
                    <span style="font-size:12px">{{ alert.description }}</span>
                </v-chip>
            </span>
        </v-alert>

        <alert-component v-if="permissions.active_property == false && item.status == 'Pendente'" :alert="alerts.active_property"></alert-component>

        <alert-component v-if="permissions.active_property == false && item.status != 'Pendente'" :alert="alerts.change_property"></alert-component>
        
        <v-alert 
            v-if="user_requests.data && user_requests.data.length > 0"
            text
            dense
            color="orange"          
            icon="mdi-alert-outline"    
        >
            <span class="black--text mr-2">Solicitação de ativação registrada com status "Novo". Para registrar uma nova solicitação é necessário aguardar a conclusão da solicitação previamente registrada. Essa solicitação foi enviada para seu gerente .<br /> 
                <div class="mt-3">
                    <span class="font-weight-bold"> Solicitante: </span> {{ user_requests.data[0].name_user_request }} 
                    <span class="font-weight-bold ml-6"> Data da solicitação: </span> {{ formatDateTime(user_requests.data[0].created_at) }}
                </div>
            </span>
        </v-alert>
        
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.status"
                        :items="items.status"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        dense
                        outlined
                        v-model="item.data_atualizacao"
                        label="Ultima Atualizaçāo"
                        append-icon="mdi-calendar-range"
                        readonly
                        filled
                        :rules="[rules.required]"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.obs_status"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Obs status (opcional)</span>
                        </template>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row dense>    
                <v-col v-if="permissions.active_property" class="text-right" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>
                <v-col v-if="!permissions.active_property" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <div v-if="item.status == 'Pendente'">
                        <v-btn
                            v-if="user_requests.data && user_requests.data.length == 0"
                            dark
                            color="primary"
                            @click="storeUserRequest()"
                            :class="$css_button"
                        >
                            SOLICITAR ATIVAÇÃO
                        </v-btn>

                        <v-chip
                            v-else
                            label
                            color="gray lighten-5" 
                        >
                        <span style="font-size:12px">SOLICITAÇÃO DE ATIVAÇÃO REGISTRADA</span> 
                        </v-chip>  
                    </div>

                    <div v-else>
                        <v-btn
                            dark
                            color="purple"
                            @click="openUserRequest()"
                            :class="$css_button"
                        >
                            SOLICITAR ALTERAÇÃO
                        </v-btn>
                    </div>    

                </v-col>    
            </v-row>
        </v-form>       
    </div>
</template>

<script>
var moment = require('moment')
import { STATUS_PROPERTY_ITEMS, TIPO_IMOVEL_PROPERTY_ITEMS } from '../config_properties/config_properties.js'
import AlertComponent from '@/components/alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent
    },

    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },

        permissions: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },

        data_atualizacao () {
            this.item.data_atualizacao = this.formatDate(this.data_atualizacao)
        },
    }, 

    data () {
        return {
            requires: [],

            alerts: {
                active_property: {
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['Você nāo possui permissāo para alterar o status do imóvel. Clique no botão "Solicitar Ativação"']
                },

                change_property: {
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['Você nāo possui permissāo para alterar o status do imóvel. Clique no botão "Solicitar Alteração"']
                },
            },

            item: {
                data_atualizacao: ''
            },

            user_request: {
                model:'properties',
                item_id:'',
                subject:'Solicitação de ativação',
            },

            items: {
                status: STATUS_PROPERTY_ITEMS,
                tipo_imovel: TIPO_IMOVEL_PROPERTY_ITEMS
            },

            data_atualizacao: '',

            calendar: {
                data_atualizacao: false,
            },

            rules: {
                required: value => !!value || 'Obrigatório'
            },

            user_requests: {
                data:[]
            },

            params: {
                user_requests: {
                    model: 'properties',
                    item_id: '',
                    subject:'Solicitação de ativação',
                    status: 'Novo',
                    check_new: 'true'   
                }
            }
        }
    },

    methods: {
        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        storeUpdate(item){
            const action = 'updateProperty' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 

        setObject(){
            this.item = this.object 
            this.setDate()  
            this.filterTipoImovel(this.item.tipo_imovel) 
            this.loadPromisseUserRequests()
        },

        setDate(){
            const current = new Date().toJSON().slice(0,10).replace(/-/g,'/')
            this.item.data_atualizacao = moment(current).format('DD/MM/YYYY')
        },

        filterTipoImovel(tipoImovel){
            this.items.tipo_imovel = this.items.tipo_imovel.filter(model => model.tipo == tipoImovel)
            if(this.items.tipo_imovel && this.items.tipo_imovel.length > 0){
                this.checkRequires(this.items.tipo_imovel[0])
            }
        },

        checkRequires(item){
            this.requires = []
            if(item.requires && item.requires.length > 0){
                item.requires.forEach(i => {
                    // type array
                    if(i.type === 'array'){
                        if(i.rule == 'greater_than_zero'){
                            if(this.item[i.field] <= 0){
                                const result = {}
                                result.description = i.description
                                result.tab = i.tab
                                this.requires.push(result)
                            }
                        }
                    }
                    // end array

                    // type string
                    if(i.type === 'string'){
                        // aba publicidade
                        if(i.field == 'publicidade'){
                            let require_publicidade = false
                            if(this.item['titulo_publicitario'] == '' || this.item['titulo_publicitario'] == null){
                                require_publicidade = true
                            }
                            if(this.item['texto_publicitario'] == '' || this.item['texto_publicitario'] == null){
                                require_publicidade = true
                            }

                            if(this.item['publicar_imovel'] == '' || this.item['publicar_imovel'] == null){
                                require_publicidade = true
                            }

                            if(require_publicidade == true){
                                const result = {}
                                result.description = i.description
                                result.tab = i.tab
                                this.requires.push(result)
                            }
                        }
                        // end aba publicidade
                    }
                    // end array
                })
            }  
        },

        storeUserRequest(){
            this.user_request.item_id = this.item.id
            
            this.$store.dispatch('storeUserRequest', this.user_request)
                        .then(response => {
                            this.$snotify.success('Solicitação enviada com sucesso! Aguarde a conclusão da solicitação junto ao seu gerente.')
                            this.loadPromisseUserRequests()
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

        loadPromisseUserRequests(){
            this.params.user_requests.item_id = this.item.id
            this.$store.dispatch('loadPromisseUserRequest', this.params.user_requests )
                        .then(response => {
                           this.user_requests = response
                        })
            
        },

        changeTab(num){
            let tab = 'tab-' + num
            this.$emit('callChangeTab', tab)
        },

        openUserRequest(){
            this.$emit('callOpenUserRequest');
        }   
        
    }
}
</script>

<style scoped>

</style>