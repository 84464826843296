<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row dense> 
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-tooltip top max-width="650px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="params.multiple_codigo"
                                outlined
                                dense
                                label="Codigos"
                                append-icon="mdi-help-circle-outline"
                                v-on="on"
                                :class="$css_field"
                                clearable
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Código</span>
                                </template>
                            </v-text-field>
                        </template>
                        <span>
                            Para pesquisar mais de um codigo basta separar por virgula
                        </span>
                    </v-tooltip>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.multiple_status"
                        :items="items.status"
                        outlined
                        multiple
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_status.length - 1 }} opções)
                            </span>
                        </template>   

                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.multiple_classificacao"
                        :items="items.classificacao"
                        outlined
                        dense
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Classificaçāo</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_classificacao.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.multiple_finalidade"
                        :items="items.finalidade"
                        outlined
                        dense
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Finalidade</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_finalidade.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="params.como_conheceu"
                        :items="me.branch.howtoknowitems"
                        outlined
                        dense
                        :class="$css_field"
                        item-text="description"
                        item-value="description"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Como conheceu?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="params.nome"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.multiple_colaborator"
                        :items="users"
                        outlined
                        dense
                        item-text="name"
                        item-value="id"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Colaborador</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.name }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.multiple_colaborator.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.email"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail</span>
                        </template>
                    </v-text-field>
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone</span>
                        </template>
                    </v-text-field>  
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.data_cadastro_from"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="params.data_cadastro_from"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Dt cadastro de</span>
                                </template>  
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="data_cadastro_from" @input="calendar.data_cadastro_from = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.data_cadastro_to"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="params.data_cadastro_to"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Dt cadastro até</span>
                                </template>    
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="data_cadastro_to" @input="calendar.data_cadastro_to = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row dense>    
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.multiple_tipo_imovel"
                        :items="items.tipos_imovel"
                        item-text="tipo"
                        item-value="tipo"
                        outlined
                        dense
                        multiple
                        clearable
                        :class="$css_field"
                        @change="searchSubTipoImovel"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo Imovel</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.tipo }}</span>
                            </div>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                                (+{{ params.multiple_tipo_imovel.length - 1 }} opções)
                            </span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="params.multiple_sub_tipo_imovel"
                        :items="items.sub_tipo_imovel"
                        outlined
                        dense
                        clearable
                        multiple
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Sub Tipo Imovel</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span>{{ item }}</span>
                            </div>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                                (+{{ params.multiple_sub_tipo_imovel.length - 1 }} opções)
                            </span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_minimo"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl mínimo</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="params.valor_maximo"
                        outlined
                        dense
                        :class="$css_field"
                        prefix="R$"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl máximo</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.dormitorios"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Dormitórios</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.banheiros"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Banheiros</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.vagas"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vagas</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_from"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área mínima</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="params.area_to"
                        outlined
                        dense
                        type="number"
                        :class="$css_field"
                        suffix="m²"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área máxima</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.em_condominio"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Em condominio?</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="params.aceita_permuta"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Aceita permuta?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete
                        v-model="params.places"
                        :items="placeitems"
                        outlined
                        dense
                        item-text="distrito_cidade_estado"
                        item-value="all"
                        :class="$css_field"
                        multiple
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Locais</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.distrito_cidade_estado }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.places.length - 1 }} opções)
                            </span>
                        </template>  

                    </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="params.cidade_estado"
                        :items="items.cidade_estado"
                        outlined
                        multiple
                        dense
                        item-text="cidade_estado"
                        item-value="cidade_estado"
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cidade</span>
                        </template>

                        <template v-slot:selection="{ item, index }">
                            <div class="mr-1" v-if="index === 0">
                                <span :class="$css_field">{{ item.cidade_estado }}</span>
                            </div>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ params.cidade_estado.length - 1 }} opções)
                            </span>
                        </template>   

                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-tooltip top max-width="650px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="params.multiple_codigo_property"
                                outlined
                                dense
                                label="Codigos do imóvel"
                                append-icon="mdi-help-circle-outline"
                                v-on="on"
                                :class="$css_field"
                                clearable
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Código dos imóveis</span>
                                </template>
                            </v-text-field>
                        </template>
                        <span>
                            Para pesquisar mais de um codigo basta separar por virgula
                        </span>
                    </v-tooltip>
                </v-col>
            </v-row>

            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="blue darken-1"
                        text  
                        @click.prevent="clear" 
                        :class="$css_button"
                    >
                        Limpar
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click.prevent="submit"
                        :class="$css_button"
                    >
                        Pesquisar
                    </v-btn>
                </v-col>  
            </v-row>
        </v-form>        
    </div>
</template>

<script>
import { STATUS_ITEMS, FINALIDADE_ITEMS, CLASSIFICACAO_ITEMS } from '@/components/facs/configs_fac/configs_fac.js'
import { TIPO_IMOVEL_PROPERTY_ITEMS,  SIM_NAO_ITEMS } from '@/components/properties/config_properties/config_properties.js' 

export default {
    created(){
        this.loadPlaceItems()
        this.loadDistinctPlaces()
        this.loadUsers()
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },

        placeitems() {
            return this.$store.state.placeitems.items.data    
        },

        users() {
            return this.$store.state.users.items.data    
        },  
    },

    watch: {
        data_cadastro_from () {
            this.params.data_cadastro_from = this.formatDate(this.data_cadastro_from)
        },

        data_cadastro_to () {
            this.params.data_cadastro_to = this.formatDate(this.data_cadastro_to)
        },

        data_retorno_from () {
            this.params.data_retorno_from = this.formatDate(this.data_retorno_from)
        },

        data_retorno_to () {
            this.params.data_retorno_to = this.formatDate(this.data_retorno_to)
        },
    }, 

    data() {
        return {
            data_cadastro_from: '',
            data_cadastro_to: '',
            data_retorno_from: '',
            data_retorno_to: '',

            calendar: {
                data_cadastro_from: false,
                data_cadastro_to: false,
                data_retorno_from: false,
                data_retorno_to: false
            },

            place_params: {
                estado: ''
            },

            cidade_estado_params: {
                type: 'facs',
                column: 'cidade'
            },

            items_params: {
                paginate: 'false'
            },

            params: {
                id: '',
                data_cadastro_from: '',
                data_cadastro_to: '',
                data_retorno_from: '',
                data_retorno_to: '',
                cidade_estado: ''
            },
            
            items: {
                status: STATUS_ITEMS,
                classificacao: CLASSIFICACAO_ITEMS,
                finalidade: FINALIDADE_ITEMS,
                tipos_imovel: TIPO_IMOVEL_PROPERTY_ITEMS,
                sub_tipo_imovel: [],
                sim_nao: SIM_NAO_ITEMS,
                cidade_estado: []
            },

            more_filters: false
        }
    },

    methods: {
        loadUsers(){
            this.$store.dispatch('loadUsers', this.items_params)
        },

        loadPlaceItems(){
            this.$store.dispatch('loadPlaceItems', this.items_params) 
        },

        loadDistinctPlaces() {
            this.$store.dispatch('loadDistinctPlaces', this.cidade_estado_params)
                        .then(response => {
                            this.items.cidade_estado = response.data   
                        })
                        .catch(error => {
                            console.log(error)
                        })
        },

        changeFilters(){
            this.more_filters = !this.more_filters
        },

        clear() {
            this.$refs.form.reset()
        },

        submit(){
            this.params.id = ''
            this.$emit('callLoadItems',this.params)
        },

        searchSubTipoImovel(){
            this.items.sub_tipo_imovel = []
            this.params.multiple_tipo_imovel.forEach(element => {
                var sub = this.items.tipos_imovel.filter(model => model.tipo === element)
                if(sub.length > 0 && sub[0].subtipos.length > 0){
                    sub[0].subtipos.forEach(element => {
                        this.items.sub_tipo_imovel.push(element)
                    });
                    
                }
                
            });
        },
        
    }
}
</script>