<template>
    <div>
        <alert-component v-if="!item.id && total >= tenant[0].app_plan.properties" :alert="alerts.limit"></alert-component>
        <alert-component v-if="alerts.check_new.show == 'true'" :alert="alerts.check_new"></alert-component>
        <alert-component v-if="alerts.foundCondominium.show == 'true'" :alert="alerts.foundCondominium"></alert-component>
        <div class="mb-6" v-if="check_new && check_new.data && check_new.data.length > 0 && alerts.check_new.show == 'true'">
            <v-data-table
                :headers="headers"
                :items="check_new.data"
                :options.sync="options"
                :server-items-length="check_new.meta && check_new.meta.total"
                class="elevation-1 table-striped caption"
                :footer-props="{
                    'items-per-page-text':'Itens por página',
                    'items-per-page-options': [5, 10],
                    'items-per-page-all-text': '',
                    'page-text': '{0}-{1} de {2}'
                }"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                        :color="item.status | statusProperty"
                        label
                        small
                    >
                        {{ item.status }}
                    </v-chip> 
                </template>

                <template v-slot:[`item.codigo`]="{ item }">
                    <v-chip
                        label
                        small
                        color="blue lighten-5" 
                    >
                        <span style="font-size:12px">COD: {{ item.codigo }}</span> 
                    </v-chip>
                </template> 

                <template v-slot:[`item.endereco`]="{ item }">
                    <span  style="font-size:14px">
                        {{ item.endereco }}
                        <span v-if="item.numero">, {{ item.numero }} </span>
                        <span v-if="item.apartamento"> - Apto: {{ item.apartamento }} </span>
                        <span v-if="item.andar">  - {{ item.andar }} andar </span>
                        <span v-if="item.tower && item.tower.nome != ''"> - Torre: {{ item.tower.nome }} </span>
                        <span v-if="item.complemento"> - Compl: {{ item.complemento }} </span>
                        <span v-if="item.bairro">  - {{ item.bairro }} </span>
                        <span v-if="item.cidade">  - {{ item.cidade }} </span>
                        <span v-if="item.estado">  - {{ item.estado }} </span>
                        <span v-if="item.cep">  - {{ item.cep | VMask('#####-###') }} </span>
                    </span>
                </template> 

            </v-data-table>
        </div>    


        <v-form ref="form">
            <v-row dense  v-if="item.id || total < tenant[0].app_plan.properties">
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cep"
                        @blur="searchCep(item.cep)"
                        :rules="[rules.required]"
                        v-mask="['#####-###']"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CEP</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.endereco"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.bairro"
                        label="Bairro"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Bairro</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.cidade"
                        label="Cidade"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cidade</span>
                        </template> 
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.estado"
                        label="Estado"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.numero"
                        label="Número"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"                        
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Número</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="!item.id || item.tipo_imovel == 'Apartamento'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.apartamento"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Apartamento</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="item.tipo_imovel == 'Apartamento'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.andar"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Andar</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="item.tipo_imovel == 'Apartamento'" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.ultimo_andar"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Ultimo andar?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.complemento"
                        label="Complemento"
                        outlined
                        dense
                        required
                        :class="$css_field"
                        :filled="alerts.check_new.show == 'false' ? true : false"
                        :readonly="alerts.check_new.show == 'false' ? true : false"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Complemento</span>
                        </template>
                    </v-text-field>
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="item.zona"
                        :items="items.zona"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Zona (opcional)</span>
                        </template>
                    </v-select>
                </v-col>  
            </v-row>
            <v-row dense v-if="item.id || alerts.check_new.show == 'false'">                
                <v-col v-if="item.condominium && item.condominium.nome != ''" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.condominium.nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        required
                        :class="$css_field"
                        filled
                        readonly
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome condominio</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.tipo_imovel"
                        :items="items.tipo_imovel"
                        :rules="[rules.required]"
                        outlined
                        dense
                        item-text="tipo"
                        item-value="tipo"
                        :class="$css_field"
                        @change="filterSubTipoImovel"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="items.sub_tipo_imovel && items.sub_tipo_imovel.length > 0" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.sub_tipo_imovel"
                        :items="items.sub_tipo_imovel"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Sub Tipo</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="items.towers && items.towers.length > 0" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.tower_id"
                        :items="items.towers"
                        item-text="nome"
                        item-value="id"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Torre</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="items.plans && items.plans.length > 0" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.plan_id"
                        :items="items.plans"
                        item-text="nome"
                        item-value="id"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Planta</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="items.positions && items.positions.length > 0" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.position_id"
                        :items="items.positions"
                        item-text="nome"
                        item-value="id"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Posições</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.estado_imovel"
                        :items="items.estado_imovel"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.finalidade"
                        :items="items.finalidade"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Finalidade</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.regiao"
                        :items="items.regiao"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Região</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.branch_id"
                        :items="branches"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                        item-value="id"
                        item-text="descricao"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Unidade</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.setor"
                        :items="items.setor"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Setor</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.para_renda"
                        :items="items.sim_nao"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Para renda?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.imovel_recaptado"
                        :items="items.sim_nao"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Recaptaçāo?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.em_condominio"
                        :items="items.sim_nao"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Em condominio?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="!item.condominium && item.em_condominio == 'Sim'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.condominio_avulso"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome do condominio (Opcional)</span>
                        </template>
                    </v-text-field>
                </v-col>   
                <v-col v-if="item.tipo_imovel == 'Casa'" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.em_vila"
                        :items="items.sim_nao"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Em vila?</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="item.tipo_imovel == 'Oportunidade Comerciais'" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.atividade"
                        :items="items.atividade"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Atividade</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="builders && builders.data.length > 0" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select
                        v-model="item.builder_id"
                        :items="builders.data"
                        item-text="nome"
                        item-value="id"
                        outlined
                        dense
                        :class="$css_field"
                        clearable
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Construtora (opcional)</span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        v-if="!item.id && alerts.check_new.show == 'false'"
                        :class="$css_button + ' mr-3'"
                        @click="newAddress"
                    >
                        Informar outro endereço
                    </v-btn>
                    
                    <v-btn
                        v-if="!item.id && alerts.check_new.show != 'false'"
                        dark
                        color="primary"
                        @click="validate2()"
                        :class="$css_button"
                    >
                        Próximo
                    </v-btn>
                    <v-btn
                        v-else
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>       
    </div>
</template>

<script>
import AlertComponent from '../../alert/AlertComponent.vue'
import { TIPO_IMOVEL_PROPERTY_ITEMS, ESTADO_IMOVEL_ITEMS, FINALIDADES_IMOVEL, REGIAO_IMOVEL_ITEMS, SIM_NAO_ITEMS, ATIVIDADE_ITEMS, SETOR_ITEMS, ZONA_IMOVEL_ITEMS } from '../config_properties/config_properties.js'

export default {
    components: {
        AlertComponent
    },

    created() {
        this.setObject()
        this.loadBuilders()
        this.loadBranches()
        this.countProperties()
        this.filterSubTipoImovel()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },
    },

    computed: {
        builders() {
            return this.$store.state.builders.items    
        },

        tenant() {
            return this.$store.state.tenants.items.data    
        },

        branches() {
            return this.$store.state.branches.items.data   
        },

        store_property() {
            return this.$store.state.properties.store    
        },
    }, 

    data () {
        return {
            alerts: {
                check_new: {
                    show: 'null',
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['O sistema encontrou outro imóvel no mesmo endereço. Por favor, verifique o imóvel em pesquisa.']
                },

                foundCondominium: {
                    show: 'null',
                    type: 'info',
                    icon: 'mdi-alert-outline',
                    messages: ['O sistema detectou que esse imovel pertence a um condominio já cadastrado. As informações em comum serāo transferidas para o novo imóvel.']
                },

                limit: {
                    show: 'null',
                    type: 'error',
                    icon: 'mdi-alert-outline',
                    messages: ['O limite de cadastro foi atingido. Entre em contato com AYT para migrar de plano ou exclua alguns registros desnecessários.']
                },
            },

            params_builders: {
                paginate: 'false'
            },

            descricao: 'teste',
            check_new: {
                meta: {
                    total: 0
                },
            },

            total: 0,

            item: {
                cep: '',
                endereco: '',
                bairro: '',
                cidade: '',
                estado: '',
                numero: '',
                complemento: '',
                condominium: {
                    nome: ''
                }  
            },

            items: {
                tipo_imovel: TIPO_IMOVEL_PROPERTY_ITEMS,
                estado_imovel: ESTADO_IMOVEL_ITEMS,
                finalidade: FINALIDADES_IMOVEL,
                regiao: REGIAO_IMOVEL_ITEMS,
                sub_tipo_imovel: [],
                builders: [],
                torres: [],
                plans: [],
                positions: [],
                sim_nao: SIM_NAO_ITEMS,
                atividade: ATIVIDADE_ITEMS,
                setor: ['Venda','Locação','Venda e Locação'],
                zona: ZONA_IMOVEL_ITEMS
            },
  
            rules: {
                required: value => !!value || 'Obrigatório',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            params_branches: {
                paginate: 'false'
            },

            headers: [
                { text: 'CODIGO', value: 'codigo' , sortable: false},
                { text: 'STATUS', value: 'status' , sortable: false},
                { text: 'ENDEREÇO', value: 'endereco' , sortable: false}
            ],

            options: {
                page: 1,
                itemsPerPage: 10,
            }
        }
    },

    methods: {
        searchCep(value){
            //this.setNull()
            if(!value) return ''
            this.$store.dispatch('loadCep', value) 
                        .then(response => {
                            this.setAddress(response)
                        })
        },

        setNull(){
            this.item.endereco = ''
            this.item.bairro = ''
            this.item.cidade = ''
            this.item.estado = ''
        },

        newAddress(){
            this.check_new = '',
            this.alerts.check_new.show = 'null'
            this.alerts.foundCondominium.show = 'null'
            this.item.cep = '',
            this.item.endereco = '',
            this.item.bairro = '',
            this.item.cidade = '',
            this.item.estado = '',
            this.item.numero = '',
            this.item.apartamento = '',
            this.item.complemento = '',
            this.item.condominium = {},
            this.item.condominium_id = '',
            this.item.tower_id = '',
            this.item.plan_id = '',
            this.item.position_id = '',
            this.item.branch_id = '',
            this.item.tipo_imovel = ''
            this.item.estado_imovel = ''
            this.item.builder_id = ''
            this.item.finalidade = ''
            this.item.regiao = ''  
            this.item.atividade = ''
            this.item.em_vila = ''
            this.item.imovel_recaptado = ''
            this.item.para_renda = '' 
            this.item.andar = '',
            this.item.ultimo_andar = '', 

            this.items.sub_tipo_imovel = []
            this.items.builders = [],
            this.items.towers = [],
            this.items.plans = [],
            this.items.positions = [],  
            this.items.tipo_imovel = TIPO_IMOVEL_PROPERTY_ITEMS,
            this.items.estado_imovel = ESTADO_IMOVEL_ITEMS,
            this.items.finalidade = FINALIDADES_IMOVEL,
            this.items.regiao = REGIAO_IMOVEL_ITEMS

            this.$refs.form.reset()
        },

        newProperty(){
            //checkNewCondominium
            this.check_new = ''
            const params = {}
            params.cep = this.item.cep
            params.endereco = this.item.endereco
            params.numero = this.item.numero
            params.apartamento = this.item.apartamento
            params.complemento = this.item.complemento
            this.$store.dispatch('newProperty', params)
                        .then(response => {
                            this.check_new = response   
                            this.alerts.foundCondominium.show = 'false' 

                            if(this.check_new.meta.total > 0){
                                if(this.check_new.data[0].tipo_condominio && this.check_new.data[0].tipo_condominio != ''){
                                    this.alerts.check_new.show = 'false'
                                    this.alerts.foundCondominium.show = 'true'
                                    this.item.condominium = this.check_new.data[0]
                                    
                                    this.filterTipoImovel(this.item.condominium.tipo_condominio)
                                    
                                    if(this.items.tipo_imovel && this.items.tipo_imovel.length == 1){
                                        this.item.tipo_imovel = this.items.tipo_imovel[0].tipo
                                        this.filterSubTipoImovel()
                                    }
                                    if(this.item.condominium && this.item.condominium.builder_id != ''){
                                        this.item.builder_id = this.item.condominium.builder_id
                                        this.filterBuilder(this.item.builder_id)        
                                    }
                                    if(this.item.condominium && this.item.condominium.finalidade != ''){
                                        this.item.finalidade = this.item.condominium.finalidade   
                                    }
                                    if(this.item.condominium && this.item.condominium.regiao != ''){
                                        this.item.regiao = this.item.condominium.regiao   
                                        this.filterRegiao(this.item.regiao)
                                    }
                                    if(this.item.condominium && this.item.condominium.towers.length > 0){
                                        this.items.towers = this.item.condominium.towers
                                    }
                                    if(this.item.condominium && this.item.condominium.plans.length > 0){
                                        this.items.plans = this.item.condominium.plans
                                    }
                                    if(this.item.condominium && this.item.condominium.positions.length > 0){
                                        this.items.positions = this.item.condominium.positions
                                    }    
                                }else{
                                    this.alerts.check_new.show = 'true'
                                    this.item.condominium = ''
                                }
                                
                            }else{
                                this.alerts.check_new.show = 'false'
                                this.item.condominium = ''
                            }
                        })
                        .catch(error => {
                            
                        })
        },

        setAddress(address){
            this.item.endereco = address.logradouro
            this.item.bairro = address.bairro
            this.item.cidade = address.localidade
            this.item.estado = address.uf
        },

        setObject(){
            if(this.object && this.object.id != undefined){
                this.item = this.object
                this.items.sub_tipo_imovel = []
                
                if(this.item.sub_tipo_imovel && this.item.sub_tipo_imovel != ''){
                    //this.items.sub_tipo_imovel.push(this.item.sub_tipo_imovel) 
                }
                if(this.item.condominium && this.item.condominium.id){
                    if(this.item.condominium && this.item.condominium.tipo_condominio != ''){
                        this.filterTipoImovel(this.item.condominium.tipo_condominio)
                        this.filterSubTipoImovel()
                    }

                    this.item.condominium.towers = JSON.parse(this.item.condominium.json_towers);
                    if(this.item.condominium.towers && this.item.condominium.towers.length > 0){
                        this.items.towers = this.item.condominium.towers
                    }

                    this.item.condominium.plans = JSON.parse(this.item.condominium.json_plans);
                    if(this.item.condominium.plans && this.item.condominium.plans.length > 0){
                        this.items.plans = this.item.condominium.plans
                    }

                    this.item.condominium.positions = JSON.parse(this.item.condominium.json_positions);
                    if(this.item.condominium.positions && this.item.condominium.positions.length > 0){
                        this.items.positions = this.item.condominium.positions
                    }
                }
                
            }
        },

        loadBuilders () {
            this.$store.dispatch('loadBuilders', this.params_builders) 
        },

        loadBranches () {
            this.$store.dispatch('loadBranches', this.params_branches) 
        },

        countProperties(){
            this.$store.dispatch('countProperties')
                        .then(response => {
                            this.total = response.meta.total 
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        validate2 () {
            if (this.$refs.form.validate()) {
                this.newProperty()
            }
        },

        storeUpdate(item){
            item.cep = this.onlyNumber(item.cep)
            if(item.condominium && item.condominium.id != ''){
                item.condominium_id = item.condominium.id 
                item.em_condominio = 'Sim'
            }
            if(item.tipo_imovel == 'Apartamento'){
                item.em_condominio = 'Sim'
            }

            const action = item.id ? 'updateProperty' : 'storeProperty' 
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')

                            if(action == 'storeProperty'){
                                this.$store.commit('STORE_PROPERTIES', true)
                            }
                            //if(action == 'storeProperty'){
                            this.$emit('callLoadItem', this.item.id)
                            //}
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        },
        
        filterTipoImovel(tipoImovel){
            var tipo_imovel = ''

            switch (tipoImovel) {
                case "Apartamento / Sala Comercial":
                    tipo_imovel = this.items.tipo_imovel.filter(model => model.tipo == 'Apartamento' || model.tipo == 'Sala Comercial')
                    break;
                case "Casa / Terreno":
                    tipo_imovel = this.items.tipo_imovel.filter(model => model.tipo == 'Casa' || model.tipo == 'Terreno')
                    break;
                default: 
                    tipo_imovel = this.items.tipo_imovel.filter(model => model.tipo == tipoImovel)
            }
            this.items.tipo_imovel = tipo_imovel
        },

        filterSubTipoImovel(){
            if(this.item.tipo_imovel){
                this.items.finalidade = []
                this.items.sub_tipo_imovel = []
                var tipo_imovel
                tipo_imovel = this.items.tipo_imovel.filter(model => model.tipo == this.item.tipo_imovel)
                if(tipo_imovel[0]){
                    this.items.sub_tipo_imovel = tipo_imovel[0].subtipos
                    this.items.finalidade = tipo_imovel[0].finalidade
                }
            }
        },

        filterBuilder(id){
           var builders
           builders = this.items.builders.filter( model => model.id == id)
           this.items.builders = builders
        },

        filterRegiao(regiao){
           var regioes
           regioes = this.items.regiao.filter( model => model == regiao)
           this.items.regiao = regioes
        },
        
    },
}
</script>

<style scoped>

</style>