<template>
  <v-app id="inspire">
    <v-row dense>
        <v-col cols="8" class="hidden-sm-and-down">
            <v-card 
                height="100%" 
                :style="images.back"
            >
            </v-card>                
        </v-col>
        <v-col class="text-center pa-5 white">
            <img src="img/logo.png" width="200" style="margin-top: 200px;">
               
            <alert-component v-if="alerts.confirmrequest.show" class="mt-6" :alert="alerts.confirmrequest"></alert-component>

            <router-link :to="{ 
                name: 'login', 
            }">
                <v-btn
                    class="caption mt-6 font-weight-bold"
                    color="primary"
                    large
                    block
                    elevation="0"       
                >
                    Voltar para tela de login
                </v-btn>
            </router-link>

            <p>&nbsp;</p>
            <p class="caption"> {{ new Date().getFullYear() }} — Real State </p>        
        </v-col>
    </v-row>

    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
import AlertComponent from '../alert/AlertComponent.vue'

export default {
    name: 'CloseRequestPasswordComponent',

    components: {
        AlertComponent
    },

    created(){
        this.closeRequestPassword()
    },

    data () {   
        return {
            alerts: {
                confirmrequest: {
                    show: false,
                    type: 'success',
                    icon: 'mdi-account-check-outline',
                    messages: ['Uma nova senha foi gerada e foi enviada no seu email.']
                },
            },

            images: {
                logo: 'logo.png',
                back: `background: url('./img/welcome.jpg') no-repeat center center; background-size: cover;`
            },
        }
    },
    
    methods: {
        closeRequestPassword(){
            if(this.$router.currentRoute.query.request && this.$router.currentRoute.query.request != ''){
                const params = {}
                params.item_id = this.$router.currentRoute.query.request 

                this.$store.dispatch('closeRequestPassword', params)
                        .then(() => {
                           this.alerts.confirmrequest.show = true
                        })
                        .catch(error => {
                            this.loading = false
                            this.$snotify.error('erro', error)
                        })
            }
        }
    }
}
</script>

<style scoped>
#inspire {
  background-color:#f1f4f6;
  font-family: 'Verdana';
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  width: 50%;
}

</style>