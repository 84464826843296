<template>
    <div>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <slide-group-component :object="object"></slide-group-component>
                </v-col>    
            </v-row>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <show-main-component class="mt-4" :object="object" :permissions="permissions"></show-main-component>
                </v-col>
            </v-row>
            <v-row dense v-if="object.condominium_id && object.condominium_id != ''">
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center">
                   <v-btn
                        v-if="!condominium.id"
                        depressed
                        class="ml-2"
                        color="warning"
                        label
                        small
                        :class="$css_report_list"
                        @click="showCondo(object.condominium_id)"
                        :loading="preloader"
                    >
                        EXIBIR DADOS DO CONDOMÍNIO
                    </v-btn> 

                    <span v-else>
                        <show-condo-property-component :object="condominium"></show-condo-property-component>
                    </span>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <map-component class="mt-4" :object="object"></map-component>
                </v-col>
            </v-row>

    </div>
</template>

<script>
import SlideGroupComponent from '../../slidegroup/SlideGroupComponent.vue'
import ShowMainComponent from '../show/show_partials/ShowMainComponent.vue'
import MapComponent from '../../maps/MapComponent.vue'
import ShowCondoPropertyComponent from '@/components/condominiums/show/ShowCondoPropertyComponent.vue'

export default {
    components: {
        SlideGroupComponent,
        ShowMainComponent,
        MapComponent,
        ShowCondoPropertyComponent
    },

    props: {
        object: {
            require: true,
        },

        permissions: {
            require: true,
        }
    }, 

    data () {
        return {
            item: {
                photos: []
            },
            condominium: {},
            preloader: false
        }
    },

    methods: {
        showCondo (id) {    
            this.preloader = true
            this.$store.dispatch('loadCondominiumProperty', id) 
                        .then(response => {
                            this.condominium = response.data
                        })
                        .finally(() => this.preloader = false)
                                   
        },   
    }

}
</script>