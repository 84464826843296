import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                excel: '#006f00'
            },
        },
    },
    
    typography: {
        font: 'Poppins, sans-serif',  // Define a nova fonte global
    },
});
